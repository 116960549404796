<template>
  <div :class="hasMonitor ? 'is-monitor-assigned' : 'help-models'">
    <div class="help-models-profile">
      <Avatar class="help-models-img" :user="model" />
      <div class="help-models-description">
        <span class="help-models-description-name">{{ changeName(model.artisticName) }}</span>
        <span class="help-models-description-city">{{ model.roomTemp }}</span>
      </div>
    </div>
    <label :class="hasMonitor ? 'block-events' : 'checkboxModel'">
      <input class="help-check" type="checkbox" @change="toggleCheck" />
      <Avatar v-if="hasMonitor" class="help-check-image" :user="model.temporalMonitor" />
      <iconic v-else name="check" />
    </label>
  </div>
</template>

<script>
  export default {
    props: ["msg", "model"],

    computed: {
      profileUser() {
        return this.$store.state.user.profile;
      },
      hasMonitor() {
        return !!this.model.temporalMonitor?.id;
      },
      changeName() {
        return function(modelName) {
          return modelName[0].toUpperCase() + modelName.substring(1);
        };
      },
      isCurrentMonitor() {
        return this.hasMonitor && this.model.temporalMonitor.id === this.profileUser._id;
      },
    },
    methods: {
      toggleCheck() {
        if (!this.isCurrentMonitor && this.hasMonitor) {
          return;
        }

        const body = {
          modelId: this.model._id,
          assing: !this.isCurrentMonitor,
        };

        const temporalMonitor = {
          id: this.profileUser._id,
          user: this.profileUser.user,
          picture: this.profileUser.picture,
        };

        this.model.temporalMonitor = this.isCurrentMonitor ? null : temporalMonitor;

        this.$axios.put(`/chat/${this.msg._id}`, body);
      },
    },
  };
</script>

<style lang="scss">
  .is-monitor-assigned,
  .help-models {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: white;
    padding: 5px 10px;
    border-radius: 27px;
    margin: 10px 0;

    .help-models-profile {
      display: flex;
      align-items: center;

      .help-models-img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }

      .help-models-description {
        display: flex;
        flex-direction: column;
        height: 40px;

        span {
          font-size: 13px;
        }

        .help-models-description-city {
          border-radius: 9px;
          width: 120px;
          height: 20px;
          text-align: center;
          padding: 0 3px;
          text-transform: uppercase;
          font-weight: 600;
          color: #fff;
          background-color: #bd0909;
        }
      }
    }
  }
  .handleCheck {
    pointer-events: none;
    opacity: 0.2;
  }
  .help-check-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    object-fit: cover;
  }

  .block-events,
  .checkboxModel {
    position: relative;
    text-align: center;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #5b5957;
    color: #fff;
    cursor: pointer;
    input[type="checkbox"] {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
    }
    * {
      pointer-events: none;
    }
  }
  .is-monitor-assigned {
    background-color: #5b5957;
    .help-models-description-name {
      color: #fff;
    }
  }
</style>
